<template>
	<div class="login">
		<div class="form">
			<div class="input phone" :class="{error, hide: (stage != 'phone')}">
				<label>הטלפון שלך:</label>
				<input v-model="index.phone" type="tel" autocomplete="off" maxlength="10">
				<span class="active">{{index.phone}}</span>
			</div>
			<div class="input code" :class="{error, hide: (stage != 'code')}">
				<label>קוד האימות שקיבלת:</label>
				<input ref="code" v-model="index.code" type="tel" autocomplete="off" maxlength="6">
				<span v-for="i in 6" :key="i" :class="{active: (!index.code ? (i == 1) : i-1 == index.code.length)}">{{(index.code && index.code[i-1] ? index.code[i-1] : '')}}</span>
			</div>

			<div class="button" :class="{hide: (stage != 'phone')}" @click="login">התחבר</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	data(){
		return {
			stage: 'phone',
			index: {},
			loading: false,
			error: false
		}
	},
	watch:{
		'index.code'(){
			if(this.index.code && this.index.code.length == 6) this.verify();
		}
	},
	methods: {
		login(){
			if(! this.index.phone) return this.error = true;

			this.error = false;
			this.stage = 'code';
			setTimeout(() => this.$refs.code.focus(), 500);

			axios.post(process.env.MIX_KIPI_API+'dashboard/__auth/login', {phone: this.index.phone})
			.then(result => {
				this.index.auth = result.data.auth;
			})
			.catch(() => {
				this.stage = 'phone';
				this.error = true;

				VueAlerts.flash({
					text: 'אירע שגיאה, מספר הטלפון אינו תקין',
					closeable: false
				});
			});
		},
		verify(){
			this.$root.loading = true;

			axios.post(process.env.MIX_KIPI_API+'dashboard/__auth/login/verify',
				{auth: this.index.auth, phone: this.index.phone, code: this.index.code})
			.then(result => {
				if(! result.data.token) throw new Exception('error');

				localStorage.setItem('token', result.data.token);
				window.location = '/';
			})
			.catch(() => {
				this.$root.loading = false;
				this.index.code = '';

				VueAlerts.flash({
					text: 'קוד אימות לא תקין',
					closeable: false
				});
			});
		}
	}
}
</script>
