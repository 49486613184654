import Vue from 'vue';

require('@/components/alerts/VueAlerts');

Vue.component('loader', require('@/components/loader').default);
Vue.component('login', require('@/pages/dashboard/login').default);

new Vue({
	data(){
		return {
			loading: false
		}
	},
	created(){
		if(localStorage.token) return window.location.href = "/";
	}
}).$mount('#app');
